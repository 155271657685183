@use '~@angular/cdk' as cdk;
@import './bootstrap.custom.scss';

@include cdk.overlay();

html,
body {
  height: 100%;
  background-color: white;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
.hidden {
  display: none;
}
.app-page-container {
  overflow: hidden;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

li:focus {
  outline: none;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.ng-scroll-content {
  height: 100%;
}

.center {
  text-align: center;
}

h1,
h2,
h3,
h4 {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.3rem;
  font-weight: 600;
}

h4 {
  font-size: 1.1rem;
}

app-displayed-projects {
  width: 100%;
}
.page,
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

.scrollable {
  overflow: auto;
  margin-right: 0 !important;
}

.hidden-on-mobile {
  display: none;
}
.only-on-mobile {
  display: block;
}
// .hidden-on-tablet {
//   display: block;
// }
// .only-on-tablet-portrait {
//   display: none;
// }
// .only-on-tablet-landscape {
//   display: none;
// }
@media screen and (min-width: 480px) {
  .hidden-on-mobile {
    display: block;
  }
  .only-on-mobile {
    display: none;
  }
}
// @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
//   .only-on-tablet-portrait {
//     display: block;
//   }
//   .hidden-on-tablet {
//     display: none !important;
//   }
// }

// @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
//   .only-on-tablet-landscape {
//     display: block;
//   }
//   .hidden-on-tablet {
//     display: none !important;
//   }
// }

// Bootstrap Modal

// .modal-dialog {
//   max-width: 1000px;
// }

.cdk-overlay-container {
  z-index: 1051;
}

.smallSpinner {
  width: 16px !important;
  height: 16px !important;
  border-width: 0.17em;
  animation-duration: 2.75s;
}
.hover-active {
  &:hover {
    color: $theme-active-menu !important;
  }
}
.form-control {
  textarea {
    border-bottom: none;
    color: $blue;
  }
  textarea:focus {
    border-bottom: none;
  }
}
.btn.disabled,
.btn:disabled {
  opacity: 0.35 !important;
  cursor: not-allowed;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: $theme-active-menu;
  outline: 0;
  box-shadow: 0 0 0 0.2rem $theme-active-menu-transparent;
}

.workflow-node-drawer {
  > div {
    position: absolute;
    right: 0;
    margin: 0;
    height: calc(100vh - 75px) !important;
    top: 75px;
    .modal-content {
      height: calc(100vh - 75px);
      overflow-y: hidden;
    }
  }
}

.litegraph-editor {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--solution-editor-background);
  color: #eee;
  font-size: 14px;
  position: relative;
}
