$blue: rgb(49, 198, 189);
$purple: #ba74ff;
$red: #e94545;
$orange: #f29c14;
$green: #71f79f;
$lighter: #fafafa;
$light: #eeebeb;
$darker: #303339;
$dark: #353940;
$black: #24262b;
$grey: #959595;
$white: #fff;
$transparent: transparent;

$theme-colors: (
  'primary': $blue,
  'secondary': $purple,
  'success': $green,
  'info': $grey,
  'warning': $orange,
  'danger': $red,
  'light': $lighter,
  'dark': $dark,
  'transparent': $transparent,
);

$theme-secondary-link: #ba74ff;
$theme-secondary-icons: #959595;
$theme-header: #363940;
$theme-logo-background: #303339;
$theme-background-left-sidebar: #24262b;
$theme-active-menu: rgb(49, 198, 189);
$theme-call-to-action: #f29c14;
$theme-alert: #e94545;

// $theme-icon-check: #d29c9c;
$theme-icon-check: #e94545;

//THEME DECLINATION
$theme-active-menu-transparent: rgba(49, 198, 189, 0.2);
$theme-secondary-icons-lighter: rgba(149, 149, 149, 0.3);
$theme-active-menu-lighter: rgb(151, 226, 222);
$theme-active-menu-lighter-transparent: rgba(151, 226, 222, 0.2);
$theme-secondary-link-lighter: #c081ff;
$theme-secondary-link-darker: #5d00b9;
$theme-secondary-icons-lighter: #9f9f9f;
$theme-active-menu-10-darker: #2cb2aa;
$theme-active-menu-contrast: white;
$theme-logo-background-10-lighter: #42464e;
$theme-logo-background-20-lighter: #525761;
$theme-logo-background-30-lighter: #676e7a;
$theme-grey-half-opacity: rgba(149, 149, 149, 0.5);

$theme-icons-light: #dee2e6;

//TILES
$theme-active-menu-hover: rgba(49, 198, 189, 0.2);
$theme-tile-color: #fafafa;
$theme-tile-folder-color: #dedddd;
$theme-tile-border-color: black;
$theme-tile-group-color: #747474;
$theme-tile-group-files-color: #ffffff;

$theme-selected-color: #31c6bd;
$theme-temporary-color: #e99e1c;

$theme-file-card-icon: grey;

//TEXT
$theme-dark-text: rgb(33, 37, 41);
//TOPNAV
$theme-top-nav-background-color: #fafafa;

//BACKGROUND
$theme-top-bar-background-color: #34353b;
$theme-top-bar-link-color: white;
$theme-header-top-bar-link-fade-color: rgb(199, 198, 198);
$theme-background-color: white;
$theme-background-color-darker: #f2f2f2;
$theme-background-color-dark: #f5f5f5;

//OVERLAY
$theme-overlay-color: rgba(0, 0, 0, 0.242);

//BUTTONS
$theme-dark-btn-icon: white;
$theme-dark-btn-color: #34353b;
$theme-dark-btn-color-hover: rgba(52, 53, 59, 0.8);
//BORDERS
$theme-classic-border: black 1px solid;
$theme-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
$theme-box-shadow-hover: 0 1px 3px rgba(0, 0, 0, 0.24), 0 1px 2px rgba(0, 0, 0, 0.48);
$theme-light-border: #979797;

// LINK
$link-color: $blue;
$link-decoration: none;
$link-hover-decoration: underline;
$link-hover-color: darken($link-color, 15%);

//TOOLTIPS
// $tooltip-max-width: 200px;
// $tooltip-color: theme-color('secondary');
// $tooltip-bg: theme-color('light');
// $tooltip-opacity: 0.9;
// $tooltip-padding-y: 3px;
// $tooltip-padding-x: 8px;
// $tooltip-margin: 0;
// $tooltip-arrow-width: 5px;
// $tooltip-arrow-height: 5px;
// $tooltip-arrow-color: $tooltip-bg;

//PAGINATION
$pagination-color: $black;
$pagination-bg: $lighter;
$pagination-hover-color: $darker;
$pagination-hover-bg: darken($lighter, 10%);
$pagination-active-color: $lighter;
$pagination-active-bg: $darker;
$pagination-active-border-color: $darker;
$pagination-disabled-bg: $white;
$enable-shadows: false;

//--------- mims components :) ----
:root {
  // solution editor
  --solution-editor-background: #2d3036;

  // component border: select, autocomplete...
  --component-color: rgb(118, 118, 118);
  --component-border-hover: #35383f;
  --form-control-background: #{$white};

  // dropdown
  --dropdown-on: #35383f;
  --dropdown-background: rgb(230, 230, 230);
  --dropdown-hover-on: rgb(255, 255, 255);
  --dropdown-hover-background: rgb(59, 61, 66);
  --dropdown-selected-on: rgb(255, 255, 255);
  --dropdown-selected-background: rgb(45, 48, 54);

  // buttons
  --button-on: #f4f4f4;
  --button-background: #35383f;
  --button-hover-on: #f4f4f4;
  --button-hover-background: #747474;

  // data-table
  --data-table-header-color: #303339;
  --data-table-data-color: #24262b;
  --data-table-body-background: #ffffff;
  --data-table-header-backgrouond: #f0f1f4;
  --data-table-row-even-background: #d4d9e2;
  --data-table-row-odd-background: #efefef;

  // Layout components
  --sidebar-on: #{$grey};

  --topbar-on: #{$grey};

  --ribbon-on: #{$grey};
  --ribbon-background: #363940;
  --ribbon-surface-background: #3f434b;
  --ribbon-hover-background: #303339;
  --main-background: #2d3036;

  // ********** temporarily supporting a second (dark) theme, until we refactor all app with a theme
  --dark-mode-modal-background: #35383f;
  --dark-mode-modal-secondary: rgb(59, 62, 70);
  --dark-mode-modal-foreground: #aba9a9;
  --dark-mode-button-background: #414857;
  --dark-mode-formcontrol-background: #42454d;
  // --dark-mode-component-color: #c5c2c2;
  --dark-mode-component-color: #{$light};
}
